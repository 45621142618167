import React, { createContext, useReducer } from 'react'

import cloneDeep from 'lodash/cloneDeep'

import { serverSideQuery, useQuery } from '@/api'
import { CMS_HOME_ENDPOINT } from '@/constants'
import { DiscoverCategory } from '@/types/enums'

import { topPickByCategorySelector } from './selectors'
import type {
    CmsHomeContextType,
    CmsHomeDataType,
    CmsHomeProviderProps,
    CmsHomeSelectors,
    selectedDiscoverCategoryState,
} from './types'

export enum ActionType {
    ON_SELECTED_TOP_CATEGORY_CHANGE,
}

interface OnSelectedTopCategoryChangeAction {
    type: ActionType.ON_SELECTED_TOP_CATEGORY_CHANGE
    payload: {
        category: DiscoverCategory
    }
}

const defaultSelectedTopCategoryState = {
    selectedDiscoverCategory: DiscoverCategory.TOP_PICKS,
}

const defaultSelectedTopCategoryContextValue: CmsHomeContextType = {
    state: defaultSelectedTopCategoryState,
    selectors: {
        topPickByCategorySelector: topPickByCategorySelector(defaultSelectedTopCategoryState),
    },
    dispatch: {
        onSelectedTopCategoryChangeAction: () => undefined,
    },
}

const selectedTopCategoryChangeAction = (
    dispatch: React.Dispatch<OnSelectedTopCategoryChangeAction>,
    payload: { category: DiscoverCategory },
) => {
    dispatch({ type: ActionType.ON_SELECTED_TOP_CATEGORY_CHANGE, payload: payload })
}

const context = createContext<CmsHomeContextType>(defaultSelectedTopCategoryContextValue)
const { Provider } = context

const useCmsHomeReducer = (): [selectedDiscoverCategoryState, React.Dispatch<OnSelectedTopCategoryChangeAction>] => {
    const defaultClone = defaultSelectedTopCategoryState

    return useReducer((state: selectedDiscoverCategoryState, action: OnSelectedTopCategoryChangeAction) => {
        const dataClone = cloneDeep(state)
        dataClone.selectedDiscoverCategory = action.payload.category
        return dataClone
    }, defaultClone)
}

export const CmsHomeProvider: React.$FC<CmsHomeProviderProps> = ({ children, initialProps }) => {
    const { data, error, fetching } = useQuery<CmsHomeDataType>({
        endpoint: CMS_HOME_ENDPOINT,
        initialFetchDisabled: !!initialProps?.data,
        initialData: initialProps?.data,
    })

    const [selectedDiscoverCategoryState, dispatch] = useCmsHomeReducer()

    const state = { data, error, fetching, ...selectedDiscoverCategoryState }
    const selectors: CmsHomeSelectors = {
        topPickByCategorySelector: topPickByCategorySelector(state),
    }

    return (
        <Provider
            value={{
                state,
                selectors: selectors,
                dispatch: {
                    onSelectedTopCategoryChangeAction: (payload) => selectedTopCategoryChangeAction(dispatch, payload),
                },
            }}
        >
            {children}
        </Provider>
    )
}

export const serverSideRead = serverSideQuery<CmsHomeDataType>(CMS_HOME_ENDPOINT)
export default context
