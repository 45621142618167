import { HomePageCategory } from './types'

export const TOP_HOME_PAGE_CATEGORIES: HomePageCategory[] = [
    {
        title: 'Country & Folk',
        imgLink:
            'https://media.vsstatic.com/image/upload/f_auto,q_auto/Product/tests/homepage-redesign-category-browse/country.jpg',
        link: '/concerts/country-folk',
    },
    {
        title: 'MLB Baseball',
        imgLink:
            'https://media.vsstatic.com/image/upload/f_auto,q_auto/Product/tests/homepage-redesign-category-browse/mlb.jpg',
        link: '/mlb-baseball',
    },
    {
        title: 'Rock',
        imgLink:
            'https://media.vsstatic.com/image/upload/f_auto,q_auto/Product/tests/homepage-redesign-category-browse/rock.jpg',
        link: '/concerts/hard-rock',
    },
    {
        title: 'NFL Football',
        imgLink:
            'https://media.vsstatic.com/image/upload/f_auto,q_auto/Product/tests/homepage-redesign-category-browse/nfl.jpg',
        link: '/nfl',
    },
    {
        title: 'Pop',
        imgLink:
            'https://media.vsstatic.com/image/upload/f_auto,q_auto/Product/tests/homepage-redesign-category-browse/pop.jpg',
        link: '/concerts/pop',
    },
    {
        title: 'Festivals',
        imgLink:
            'https://media.vsstatic.com/image/upload/f_auto,q_auto/Product/tests/homepage-redesign-category-browse/festival.jpg',
        link: '/concerts/festivals',
    },
]
