import React from 'react'

import { Pill, Text } from '@vividseats/vivid-ui-kit'

import styles from './styles.module.scss'

const HomeOpenerBadge: React.FC = () => {
    return (
        <Pill size="lg" className={styles.homeOpener} data-testid="home-opener-pill">
            <Text altFont weight="regular-bold">
                Home Opener
            </Text>
        </Pill>
    )
}

export default HomeOpenerBadge
