import React from 'react'

import { Pill, Text } from '@vividseats/vivid-ui-kit'

import styles from './styles.module.scss'

interface LimitedInventoryBadgeProps {
    label?: string
}

const LimitedInventoryBadge: React.FC<LimitedInventoryBadgeProps> = ({ label }) => {
    return (
        <Pill size="lg" className={styles.limitedInventoryPill} data-testid="limited-inventory-pill">
            <Text altFont weight="regular-bold">
                <span aria-label="fire" role="img">
                    🔥
                </span>
                {label}
            </Text>
        </Pill>
    )
}

export default LimitedInventoryBadge
