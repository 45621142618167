import { hermesService } from '@/api'
import { BRAZE_EMAIL_SUBSCRIPTION_STATE_ENDPOINT } from '@/constants'
import { EMAIL_SUBSCRIPTION_STATE } from '@/types/app-types'

const setBrazeEmailSubscriptionState = async (email: string, subscription_state: EMAIL_SUBSCRIPTION_STATE) => {
    try {
        await hermesService.post(BRAZE_EMAIL_SUBSCRIPTION_STATE_ENDPOINT, { email, subscription_state })
    } catch (error) {
        console.error(`Braze: setting email subscription failed with: ${(error as Error).message}`)
    }
}

export default setBrazeEmailSubscriptionState
