import React, { FC, SVGProps } from 'react'

type Props = SVGProps<SVGSVGElement> & {
    fontSize?: string | number
}

const FootballIcon: FC<Props> = ({ fontSize, color = '#0A0B09', ...props }) => {
    return (
        <svg
            viewBox="0 0 7 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={fontSize || '1rem'}
            height={fontSize || '1rem'}
            color={color}
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.8828 0.887917C3.93515 0.870545 3.99283 0.884203 4.03184 0.923208L6.57721 3.46858C6.61622 3.50759 6.62988 3.56527 6.6125 3.61762C6.35794 4.43937 5.91637 5.19093 5.32246 5.81329C4.69415 6.40936 3.93619 6.85164 3.10813 7.10537C3.05577 7.12275 2.9981 7.10909 2.95909 7.07008L0.430338 4.54104C0.391445 4.50209 0.377795 4.44456 0.395046 4.39229C0.648846 3.5643 1.09089 2.8063 1.68655 2.17767C2.30925 1.58397 3.06097 1.14253 3.8828 0.887917ZM4.32992 3.40792L4.72046 3.01737V3.01883C4.7773 2.96388 4.80007 2.88254 4.78001 2.80607C4.75996 2.7296 4.7002 2.6699 4.62371 2.64991C4.54722 2.62993 4.4659 2.65278 4.41101 2.70967L4.02075 3.10021C3.9934 3.12764 3.95625 3.14305 3.9175 3.14305C3.87876 3.14305 3.84161 3.12764 3.81425 3.10021L3.70488 2.99083C3.61869 2.91052 3.48438 2.91289 3.40107 2.99619C3.31777 3.0795 3.3154 3.21381 3.39571 3.3L3.50392 3.40792C3.56085 3.46486 3.56085 3.55718 3.50392 3.61413L3.11163 4.00642C3.05468 4.06335 2.96237 4.06335 2.90542 4.00642L2.79575 3.89704C2.70956 3.81673 2.57525 3.8191 2.49195 3.9024C2.40864 3.98571 2.40628 4.12002 2.48659 4.20621L2.59596 4.31588C2.65289 4.37282 2.65289 4.46514 2.59596 4.52208L2.20688 4.91263C2.12657 4.99882 2.12894 5.13313 2.21224 5.21643C2.29554 5.29974 2.42986 5.3021 2.51605 5.22179L2.90659 4.83154C2.96354 4.77461 3.05585 4.77461 3.1128 4.83154L3.22246 4.94092C3.30865 5.02123 3.44297 5.01886 3.52627 4.93556C3.60957 4.85225 3.61194 4.71794 3.53163 4.63175L3.42225 4.52208C3.36532 4.46514 3.36532 4.37282 3.42225 4.31588L3.81455 3.92358C3.87149 3.86665 3.96381 3.86665 4.02075 3.92358L4.13042 4.03296C4.21661 4.11327 4.35092 4.1109 4.43423 4.0276C4.51753 3.94429 4.5199 3.80998 4.43959 3.72379L4.33021 3.61413C4.30284 3.58684 4.28742 3.54981 4.28734 3.51117C4.28727 3.47246 4.30259 3.43532 4.32992 3.40792Z"
                fill={color}
            />
            <path
                d="M0.323549 5.05223C0.304902 5.03335 0.277444 5.02615 0.25193 5.03344C0.226416 5.04073 0.206909 5.06135 0.201049 5.08723C0.0424994 5.72659 -0.0205033 6.38591 0.014091 7.04373C0.0373767 7.27729 0.222118 7.46203 0.455674 7.48531C1.11357 7.52046 1.77304 7.45765 2.41247 7.29894C2.43827 7.29299 2.45882 7.27352 2.46615 7.24807C2.47348 7.22262 2.46644 7.1952 2.44776 7.17644L0.323549 5.05223Z"
                fill={color}
            />
            <path
                d="M6.98379 0.95758C6.96064 0.72391 6.77586 0.539015 6.54221 0.515705C5.88257 0.477145 5.22087 0.537309 4.579 0.694205C4.55319 0.700154 4.53264 0.719626 4.52531 0.745072C4.51798 0.770518 4.52502 0.797939 4.54371 0.816705L6.68425 2.95725C6.70308 2.9759 6.73052 2.98291 6.75599 2.97559C6.78146 2.96827 6.80099 2.94776 6.80704 2.92196C6.9635 2.27963 7.02308 1.61752 6.98379 0.95758Z"
                fill={color}
            />
        </svg>
    )
}

export default FootballIcon
