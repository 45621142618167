import React, { FormEvent, useMemo, useState } from 'react'

import { Box, ButtonV2, Divider, Text, Input, Div } from '@vividseats/vivid-ui-kit'
import type { NextPage } from 'next'

import setBrazeEmailSubscriptionState from '@/api/braze/set-email-subscription-state'
import { EMAIL_SUBSCRIPTION_STATE } from '@/types/app-types'
import { trackEmailLeadCapture } from '@/utils/analytics/segment'

import styles from './styles.module.scss'

const Newsletter: NextPage = () => {
    const [userEmail, setUserEmail] = useState<string>('')
    const [validationState, setValidationState] = useState<'default' | 'error' | 'success'>('default')

    const handleUserEmailChange = (event: FormEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget
        setUserEmail(value)
        setValidationState('default')
    }

    const emailIsValid = useMemo(() => {
        const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
        return regex.test(userEmail)
    }, [userEmail])

    const handleSubmit = async (event: React.FormEvent) => {
        // Prevent default refresh on submit
        event.preventDefault()
        if (!emailIsValid) {
            return setValidationState('error')
        }
        trackEmailLeadCapture(userEmail)
        await setBrazeEmailSubscriptionState(userEmail, EMAIL_SUBSCRIPTION_STATE.OPTED_IN)
        setValidationState('success')
    }

    const hasValidationError = validationState === 'error'
    const submittedSuccessfully = validationState === 'success'

    return (
        <form onSubmit={handleSubmit} data-testid="newsletterForm">
            <Divider />
            <Box display="flex" wrap className={styles.boxContainer}>
                <Box grow shrink desktopFlexBasis={5} mobileFlexBasis={12} tabletPadding={5} mobilePadding={5}>
                    <Text className={styles.headerText} altFont>
                        Stay in the know
                    </Text>
                    <Text size="lg">
                        Join our email list and be the first to know about exclusive offers, the best in live events,
                        and more.
                    </Text>
                </Box>
                <Box
                    grow
                    shrink
                    className={styles.itemInputContainer}
                    desktopPadding={12}
                    mobilePadding={5}
                    tabletPadding={5}
                >
                    <Box
                        grow
                        shrink
                        desktopFlexBasis={7}
                        mobileFlexBasis={12}
                        className={styles.newsletterInputContainer}
                        justify="end"
                    >
                        <Input
                            label="Email Address"
                            variant="block"
                            borderRadius="medium"
                            sizing="md"
                            value={userEmail}
                            onChange={handleUserEmailChange}
                            showInvalid={hasValidationError}
                            showValid={submittedSuccessfully}
                            validation={null}
                            id="newsletterInput"
                            type="email"
                            data-testid="newsletterInput"
                        />
                        <Div marginTop={4}>
                            {submittedSuccessfully && (
                                <Text className={styles.newsletterSubmitSuccessText} data-testid="success-message">
                                    Thanks for subscribing!
                                </Text>
                            )}
                            {hasValidationError && (
                                <Text className={styles.newsletterSubmitErrorText} data-testid="error-message">
                                    Please enter a valid email.
                                </Text>
                            )}
                        </Div>
                    </Box>
                    <Box
                        grow
                        shrink
                        desktopFlexBasis={1}
                        mobileFlexBasis={12}
                        mobilePaddingTop={6}
                        mobilePaddingBottom={4}
                        tabletPaddingBottom={4}
                    >
                        <ButtonV2 block size="large" data-testid="newsletterButton" className={styles.signUpButton}>
                            Sign Up
                        </ButtonV2>
                    </Box>
                </Box>
            </Box>
        </form>
    )
}

export default Newsletter
