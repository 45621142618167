/*
NOTE: Custom hook to set Optimizely user attributes relevant to the Home Page
*/

import { UserAttributes } from '@optimizely/optimizely-sdk'
import { ReactSDKClient } from '@optimizely/react-sdk'
import { UserInfo } from '@optimizely/react-sdk/dist/utils'
import { useRecoilValue } from 'recoil'

import useOptimizelyUser from '@/hooks/use-optimizely-user'
import { isFirstPageSelector } from '@/recoil/selectors/isFirstPageInSessionSelector'

const useHomePageOptimizelyUser = (optimizely: ReactSDKClient | null): UserInfo | undefined => {
    const isFirstPage = useRecoilValue(isFirstPageSelector)
    const setAttributes = (attributes: UserAttributes) => {
        if (isFirstPage) {
            attributes.homepage_starter = true
        }
        attributes.homepage_visitor = true
    }

    return useOptimizelyUser(optimizely, setAttributes)
}

export default useHomePageOptimizelyUser
