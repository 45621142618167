import { OPTIMIZELY_FEATURE } from '@/optimizely/constants'
import { useMlbPerformerOpeningDayDesktopFeature } from '@/optimizely/features/mlb-performer-opening-day-feature/hooks/use-mlb-performer-opening-day-desktop-feature'
import { useMlbPerformerOpeningDayMobileFeature } from '@/optimizely/features/mlb-performer-opening-day-feature/hooks/use-mlb-performer-opening-day-mobile-feature'
import { useSellingFastDealsAvailableConcertPerformerFeature } from '@/optimizely/features/selling-fast-deals-available-concert-performer-feature/hooks'
import { useSellingFastDealsAvailableSportsHomePerformerFeature } from '@/optimizely/features/selling-fast-deals-available-sports-home-performer-feature/hooks'
import { useSellingFastDealsAvailableSportsPerformerFeature } from '@/optimizely/features/selling-fast-deals-available-sports-performer-feature/hooks'
import { useSellingFastDealsAvailableTheatrePerformerFeature } from '@/optimizely/features/selling-fast-deals-available-theatre-performer-feature/hooks'
import { OptimizelyHookResult } from '@/optimizely/types'
import { useOptimizelyFeature } from '@/optimizely/utils'

export const useScarcityBadgesFeature = (): boolean => {
    const [isSellingFastDealsAvailableConcertPerformerFeatureEnabled] =
        useSellingFastDealsAvailableConcertPerformerFeature()
    const [isSellingFastDealsAvailableTheatrePerformerFeatureEnabled] =
        useSellingFastDealsAvailableTheatrePerformerFeature()
    const [isSellingFastDealsAvailableSportsPerformerFeatureEnabled] =
        useSellingFastDealsAvailableSportsPerformerFeature()
    const [isSellingFastDealsAvailableSportsHomePerformerFeatureEnabled] =
        useSellingFastDealsAvailableSportsHomePerformerFeature()
    const [isMlbPerformerOpeningDayMobileFeatureEnabled] = useMlbPerformerOpeningDayMobileFeature()
    const [isMlbPerformerOpeningDayDesktopFeatureEnabled] = useMlbPerformerOpeningDayDesktopFeature()
    const isMlbPerformerOpeningDayFeatureEnabled =
        isMlbPerformerOpeningDayMobileFeatureEnabled || isMlbPerformerOpeningDayDesktopFeatureEnabled
    return (
        isSellingFastDealsAvailableConcertPerformerFeatureEnabled ||
        isSellingFastDealsAvailableTheatrePerformerFeatureEnabled ||
        isSellingFastDealsAvailableSportsPerformerFeatureEnabled ||
        isSellingFastDealsAvailableSportsHomePerformerFeatureEnabled ||
        isMlbPerformerOpeningDayFeatureEnabled
    )
}

export const useOnsalePresaleBadgesFeature = (): OptimizelyHookResult => {
    return useOptimizelyFeature(OPTIMIZELY_FEATURE.onsalePresaleBadges)
}
