import React, { useContext, useMemo } from 'react'

import { ArrowForwardIcon, Box, Column, Grid, HorizontalScroll, Link, Row, Text } from '@vividseats/vivid-ui-kit'
import NextLink from 'next/link'

import { PATHS } from '@/constants'
import cmsHomeContext from '@/context/cms-home'
import type { DiscoverItems as DiscoverItemsType } from '@/context/cms-home/types'
import { DiscoverCategories } from '@/context/cms-home/types'
import { DiscoverCategory } from '@/types/enums'

import CategoryButton from './components/category-button'
import CategoryItem from './components/category-item'
import styles from './styles.module.scss'

const DiscoverCategoryItems: React.FC = () => {
    const { state } = useContext(cmsHomeContext)

    const allCategoryItems = useMemo(() => {
        // BW-1513 All categories must be rendered (with display:block or display:none) to be present in DOM for Google to crawl links
        const top_picks = (
            <Box
                display={state.selectedDiscoverCategory === DiscoverCategory.TOP_PICKS ? 'flex' : 'none'}
                className={styles.itemWrapper}
                key={`discover-top-picks-wrapper`}
            >
                {state.data?.top_picks?.map((item: DiscoverItemsType, index: number) => (
                    <CategoryItem item={item} index={index} key={`top-picks-${index}`} />
                ))}
            </Box>
        )

        const sports = (
            <Box
                display={state.selectedDiscoverCategory === DiscoverCategory.SPORTS ? 'flex' : 'none'}
                className={styles.itemWrapper}
                key={`discover-sports-wrapper`}
            >
                {state.data?.top_sports?.map((item: DiscoverItemsType, index: number) => (
                    <CategoryItem item={item} index={index} key={`top-sports-${index}`} />
                ))}
            </Box>
        )

        const concerts = (
            <Box
                display={state.selectedDiscoverCategory === DiscoverCategory.CONCERTS ? 'flex' : 'none'}
                className={styles.itemWrapper}
                key={`discover-concerts-wrapper`}
            >
                {state.data?.top_concerts?.map((item: DiscoverItemsType, index: number) => (
                    <CategoryItem item={item} index={index} key={`top-concerts-${index}`} />
                ))}
            </Box>
        )

        const theater = (
            <Box
                display={state.selectedDiscoverCategory === DiscoverCategory.THEATER ? 'flex' : 'none'}
                className={styles.itemWrapper}
                key={`discover-theater-wrapper`}
            >
                {state.data?.top_theater?.map((item: DiscoverItemsType, index: number) => (
                    <CategoryItem item={item} index={index} key={`top-theater-${index}`} />
                ))}
            </Box>
        )

        return [top_picks, sports, concerts, theater]
    }, [
        state.data?.top_concerts,
        state.data?.top_picks,
        state.data?.top_sports,
        state.data?.top_theater,
        state.selectedDiscoverCategory,
    ])

    return (
        <Grid className={styles.grid}>
            <Row justify="center">
                <Column alignSelf="center" className={styles.discoverHeaderContainer}>
                    <Row className={styles.discoverRow}>
                        <Column>
                            <Text
                                weight="extra-extra-bold"
                                altFont={true}
                                float="left"
                                size="title-lg"
                                className={styles.discoverText}
                                as="h2"
                            >
                                Discover what’s next
                            </Text>
                        </Column>
                    </Row>
                    <Row className={styles.buttonsRow}>
                        <Column className={styles.buttonsContainer}>
                            {DiscoverCategories.map((item: DiscoverCategory, index: number) => (
                                <CategoryButton key={index} category={item} index={index} />
                            ))}
                        </Column>

                        <Column desktop={true} mobile={false}>
                            <Link
                                LinkComponent={NextLink}
                                href={PATHS.SEARCH}
                                target="_self"
                                importance="none"
                                legacyBehavior
                            >
                                <Text
                                    size="lg"
                                    altFont={true}
                                    weight="regular-bold"
                                    float="right"
                                    className={styles.findMoreText}
                                    as="span"
                                >
                                    Find More Events&nbsp;
                                    <ArrowForwardIcon fontSize="1.5rem" />
                                </Text>
                            </Link>
                        </Column>
                    </Row>
                </Column>
            </Row>
            <HorizontalScroll scroll={false} classNameForHsWrapper={styles.horizontalScrollWrapper}>
                {allCategoryItems}
            </HorizontalScroll>
        </Grid>
    )
}

export default DiscoverCategoryItems
