import React, { useMemo } from 'react'

import { ExternalOrLazyImage, Link, Text, Box } from '@vividseats/vivid-ui-kit'
import NextLink from 'next/link'

import { PATHS } from '@/constants'
import type { DiscoverItems as DiscoverItemsType } from '@/context/cms-home/types'
import { trackHomePageEvent } from '@/utils/analytics'
import { ClickLocation } from '@/utils/analytics/types'

import styles from './styles.module.scss'

interface Props {
    item: DiscoverItemsType
    index: number
}

const trackCategoryItemClick = (item: DiscoverItemsType, index: number) => {
    trackHomePageEvent({
        click_location: ClickLocation.HOME_PAGE_DISCOVER_ITEM,
        click_text: item.title,
        click_tile_location: index + 1,
        carousel_location: 2,
    })
}

const CategoryItem: React.FC<Props> = ({ item, index }) => {
    const externalImageUrl: string | undefined = useMemo(
        () =>
            item.external_image_path
                ? `https:${process.env.NEXT_PUBLIC_EXTERNAL_IMAGE_URL_PREFIX}${PATHS.TOP_PICK_EXTERNAL_IMAGE_MID_PATH}${item.external_image_path}`
                : undefined,
        [item.external_image_path],
    )

    return (
        <Link
            LinkComponent={NextLink}
            href={item.link}
            target="_self"
            importance="none"
            data-testid={`discover-category-link-${index}`}
            onClick={() => trackCategoryItemClick(item, index)}
            legacyBehavior
        >
            <Box className={styles.item}>
                <ExternalOrLazyImage externalImage={externalImageUrl} fallbackUrl={item.image_url} />
                <Text
                    altFont={true}
                    className={styles.titleText}
                    truncate={true}
                    data-testid={`discover-category-title-${index}`}
                >
                    {item.title}
                </Text>
            </Box>
        </Link>
    )
}

export default CategoryItem
