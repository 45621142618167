import React from 'react'

import { Box, Text } from '@vividseats/vivid-ui-kit'

import SearchBar from '@/components/shared/searchbar'

import styles from './styles.module.scss'

const PromoHero: React.FC = () => {
    return (
        <div className={styles.containerGradient}>
            <div
                className={styles.container}
                // Putting image as style for faster rendering and improve LCP score
                style={{
                    backgroundImage: 'url(https://a.vsstatic.com/hero/home/custom/100mth-ticket/100m-hero-bkgd.jpg)',
                }}
            >
                <div
                    className={styles.graphic}
                    // Putting image as style for faster rendering and improve LCP score
                >
                    <div className={styles.typeaheadContainer}>
                        <Box mobileDisplay="block" desktopDisplay="none" tabletDisplay="block">
                            <div data-testid="home-hero-search-input">
                                <SearchBar />
                            </div>
                        </Box>
                        <div className={styles.copy}>
                            <Text>Milestone:</Text>
                            <Box desktopMarginBottom={10} tabletMarginBottom={10} mobileMarginBottom={5}>
                                <Text as="h1" className={styles.title}>
                                    <div>100 Million</div>
                                    <div>Tickets Sold</div>
                                </Text>
                            </Box>
                            <Text className={styles.subtitle} size="subtitle-xl">
                                Honoree:
                            </Text>
                            <Text as="h2" className={styles.title}>
                                Could Be You!
                            </Text>
                        </div>

                        <div
                            className={styles.heroImage}
                            // Putting image as style for faster rendering and improve LCP score
                            style={{
                                backgroundImage:
                                    'url(https://a.vsstatic.com/hero/home/custom/100mth-ticket/100m-hero-cutout.png)',
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PromoHero
