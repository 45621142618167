import { useEffect, useContext, useState } from 'react'

import UserLocationContext from '@/context/location/context'
import { UserLocation } from '@/types/app-types'

import { GeolocatedHeroData, Polygon } from '../types'

import heroPolygons from './hero-polygons.json'

export const isPointInPolygon = (long: number, lat: number, polygon: Polygon) => {
    // ray-casting algorithm based on
    // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html

    const x = long,
        y = lat

    let inside = false

    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        const xi = polygon[i][0],
            yi = polygon[i][1]

        const xj = polygon[j][0],
            yj = polygon[j][1]

        const intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi

        if (intersect) {
            inside = !inside
        }
    }

    return inside
}

export const getGeolocatedHeroData = (longitude: number, latitude: number) => {
    const heroData = heroPolygons.find((hero) => {
        const { polygon } = hero

        return isPointInPolygon(longitude, latitude, polygon)
    })

    return heroData
}

export const useGeolocatedHero = (initialUserLocationData?: UserLocation): GeolocatedHeroData | undefined => {
    const initialState =
        initialUserLocationData && initialUserLocationData?.region !== ''
            ? getGeolocatedHeroData(initialUserLocationData.longitude, initialUserLocationData.latitude)
            : undefined

    const [heroData, setHeroData] = useState<GeolocatedHeroData | undefined>(initialState)

    const {
        selectors: { city },
        state: { data },
    } = useContext(UserLocationContext)

    const { latitude, longitude } = data || {}

    useEffect(() => {
        if (city === '' || !latitude || !longitude) {
            setHeroData(initialState)

            return
        }

        setHeroData(getGeolocatedHeroData(longitude, latitude))

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [city, latitude, longitude])

    return heroData
}

export default useGeolocatedHero
