import React from 'react'

import { Typography } from '@mui/material'
import { Pill } from '@vividseats/vivid-ui-kit'

import styles from './styles.module.scss'

interface PulsingBadgeProps {
    label: string
}

const PulsingBadge: React.FC<PulsingBadgeProps> = ({ label }) => {
    return (
        <Pill size="lg" className={styles.pulsingBadge} data-testid="pulsing-badge">
            <div className={styles.dotContainer}>
                <div className={styles.pulsingDot} />
                <div className={styles.pulsingDot} />
            </div>
            <Typography variant="caption-medium" color="indigo.800">
                {label}
            </Typography>
        </Pill>
    )
}

export default PulsingBadge
