import { TypographyPropsVariantOverrides } from '@mui/material'

export const TYPOGRAPHY_VARIANT_BY_SIZE: Record<string, keyof TypographyPropsVariantOverrides> = {
    small: 'small-regular',
    medium: 'body-regular',
}

export const ICON_SIZE_BY_SIZE: Record<string, 'xxs'> = {
    small: 'xxs',
}
