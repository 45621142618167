import React, { useContext } from 'react'

import { ButtonV2, Link, Text } from '@vividseats/vivid-ui-kit'
import classNames from 'classnames'

import cmsHomeContext from '@/context/cms-home'
import { DiscoverCategory } from '@/types/enums'
import { trackHomePageEvent } from '@/utils/analytics'
import { ClickLocation } from '@/utils/analytics/types'

import styles from './styles.module.scss'

interface Props {
    category: DiscoverCategory
    index: number
}

const trackCategoryClick = (category: DiscoverCategory, index: number) => {
    trackHomePageEvent({
        click_location: ClickLocation.HOME_PAGE_DISCOVER_CATEGORY,
        click_text: category.toLowerCase(),
        click_tile_location: index + 1,
        carousel_location: 2,
    })
}

const CategoryButton: React.FC<Props> = ({ category, index }) => {
    const { state: cmsHomeState, dispatch: CmsHomeDispatchActions } = useContext(cmsHomeContext)

    const mergedButtonClassName = classNames(styles.button, {
        [styles.muted]: category !== cmsHomeState.selectedDiscoverCategory,
    })

    const handleClick = (event: any) => {
        event.preventDefault()
        CmsHomeDispatchActions.onSelectedTopCategoryChangeAction({
            category: category,
        })
        trackCategoryClick(category, index)
    }

    if (category === DiscoverCategory.TOP_PICKS) {
        return (
            <ButtonV2
                data-testid={`discover-${category.toLowerCase().replace(' ', '-')}-button`}
                importance="text"
                className={mergedButtonClassName}
                onClick={handleClick}
            >
                <Text size="lg" weight="semi-bold" altFont={true}>
                    {category}
                </Text>
            </ButtonV2>
        )
    } else {
        return (
            <Link
                importance="text"
                data-testid={`discover-${category.toLowerCase()}-button`}
                href={`/${category.toLowerCase()}`}
                onClick={handleClick}
                className={mergedButtonClassName}
                legacyBehavior
            >
                <Text size="lg" weight="semi-bold" altFont={true}>
                    {category}
                </Text>
            </Link>
        )
    }
}

export default CategoryButton
