import type { DefaultProviderProps, DefaultStateType } from '@/types/app-types'
import { DiscoverCategory } from '@/types/enums'

export interface BlogArticle {
    id: number
    category_name: string
    title: string
    link: string
}

export interface DiscoverItems {
    id: number
    image_url: string
    external_image_path: string
    link: string
    title: string
}

export interface CmsHomeDataType {
    id: number
    created_at: string
    updated_at: string
    blog_article: BlogArticle[]
    top_picks: DiscoverItems[]
    top_sports: DiscoverItems[]
    top_theater: DiscoverItems[]
    top_concerts: DiscoverItems[]
    meta: {
        id: number
        title: string
        keywords: string
        description: string
    }
}

export interface selectedDiscoverCategoryState {
    selectedDiscoverCategory: DiscoverCategory
}

export interface CmsHomeStateType extends DefaultStateType<CmsHomeDataType> {
    selectedDiscoverCategory: DiscoverCategory
}

// Hermes's /cms/home empdpoint does not take any params
export type CmsHomeProviderProps = DefaultProviderProps<CmsHomeStateType, undefined>

export type CmsHomeSelectors = {
    topPickByCategorySelector: DiscoverItems[] | undefined
}

export interface CmsHomeContextType {
    state: CmsHomeStateType
    selectors: CmsHomeSelectors
    dispatch: {
        onSelectedTopCategoryChangeAction: (payload: { category: DiscoverCategory }) => void
    }
}

export const DiscoverCategories = [
    DiscoverCategory.TOP_PICKS,
    DiscoverCategory.SPORTS,
    DiscoverCategory.CONCERTS,
    DiscoverCategory.THEATER,
]
