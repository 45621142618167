import { createSelector } from 'reselect'

import { DiscoverCategory } from '@/types/enums'

import type { CmsHomeStateType, DiscoverItems } from './types'

export const topPickByCategorySelector = createSelector(
    (state: CmsHomeStateType) => state,
    (state: CmsHomeStateType): DiscoverItems[] | undefined => {
        switch (state.selectedDiscoverCategory) {
            case DiscoverCategory.TOP_PICKS:
                return state.data?.top_picks
            case DiscoverCategory.CONCERTS:
                return state.data?.top_concerts
            case DiscoverCategory.THEATER:
                return state.data?.top_theater
            case DiscoverCategory.SPORTS:
                return state.data?.top_sports
            default:
                return state.data?.top_picks
        }
    },
)
