import React from 'react'

import { Pill, Text } from '@vividseats/vivid-ui-kit'

import styles from './styles.module.scss'

interface MondayThursdayNightFootballBadgeProps {
    label: string
}

const MondayThursdayNightFootballBadge: React.FC<MondayThursdayNightFootballBadgeProps> = ({ label }) => {
    return (
        <Pill
            size="lg"
            className={styles.mondayThursdayNightFootballPill}
            data-testid="monday-thursday-night-football-pill"
        >
            <Text altFont weight="regular-bold">
                {label}
            </Text>
        </Pill>
    )
}

export default MondayThursdayNightFootballBadge
