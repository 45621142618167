import React, { $FC, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { serverSideQuery, useQuery } from '@/api'
import { DEFAULT_CONTEXT_VALUES, MAX_DRIVING_DISTANCE, MAX_NEARBY_DISTANCE, PRODUCTIONS_ENDPOINT } from '@/constants'
import UserLocationContext from '@/context/location/context'
import type { ProductionPage } from '@/types/app-types'

import type { TopProductionsNearYouContextType, TopProductionsNearYouProps } from './types'

const context = createContext<TopProductionsNearYouContextType>(DEFAULT_CONTEXT_VALUES)
const { Provider } = context

export const DEFAULT_PAGE_SIZE = 4

const emptyData: ProductionPage = {
    items: [],
    total: 0,
    numberOfPages: 0,
    page: 0,
}

export const TopProductionsNearYouProvider: $FC<TopProductionsNearYouProps> = ({
    children,
    initialProps,
    initialParams,
    initialFetchDisabled,
}) => {
    const [params, setParams] = useState(initialParams)
    const {
        selectors: { latLong },
    } = useContext(UserLocationContext)
    const hasPropData = !!initialProps?.data
    const defaultParams = { ...params, distinct: true }

    // update latLong
    useEffect(() => {
        // update location only if needed and if not using prop data
        if (!hasPropData && latLong !== params?.latLong) {
            setParams((prevParams) => ({ ...prevParams, latLong }))
        }
    }, [latLong, params?.latLong, hasPropData])

    // nearby fetch
    const { data, error, fetching, refetch } = useQuery<ProductionPage>({
        endpoint: PRODUCTIONS_ENDPOINT,
        params: { ...defaultParams, radius: MAX_NEARBY_DISTANCE },
        initialFetchDisabled: initialFetchDisabled || hasPropData,
        initialData: initialProps?.data || emptyData,
    })

    // driving fetch
    const usingDriving = !fetching && !!data && data.total < DEFAULT_PAGE_SIZE
    const {
        data: drivingData,
        error: drivingError,
        fetching: drivingFetching,
    } = useQuery<ProductionPage>({
        endpoint: PRODUCTIONS_ENDPOINT,
        params: { ...defaultParams, radius: MAX_DRIVING_DISTANCE },
        initialFetchDisabled: initialFetchDisabled || hasPropData,
        initialData: emptyData,
        enabled: usingDriving,
    })
    if (usingDriving && !drivingFetching && !!drivingData) {
        const nearbyItems = data.items
        const nearbyItemIds = nearbyItems.map((item) => item.id)
        drivingData.items = [...nearbyItems, ...drivingData.items.filter((item) => !nearbyItemIds.includes(item.id))]
    }

    // all fetch
    const usingAll = usingDriving && !drivingFetching && !!drivingData && drivingData.total < DEFAULT_PAGE_SIZE
    const {
        data: allData,
        error: allError,
        fetching: allFetching,
    } = useQuery<ProductionPage>({
        endpoint: PRODUCTIONS_ENDPOINT,
        params: { ...defaultParams, includeIpAddress: false, radius: undefined, latLong: undefined },
        initialFetchDisabled: initialFetchDisabled || hasPropData,
        initialData: emptyData,
        enabled: usingAll,
    })
    if (usingAll && !allFetching && !!allData) {
        const drivingDataItems = drivingData.items
        const drivingItemIds = drivingDataItems.map((item) => item.id)
        allData.items = [...drivingDataItems, ...allData.items.filter((item) => !drivingItemIds.includes(item.id))]
    }

    const setStartDate = useCallback((startDate: string) => {
        setParams((prevParams) => ({ ...prevParams, startDate }))
    }, [])

    const setEndDate = useCallback((endDate: string | undefined) => {
        setParams((prevParams) => ({ ...prevParams, endDate }))
    }, [])

    // set state
    const state = useMemo(
        () => ({
            data: usingAll ? allData : usingDriving ? drivingData : data,
            error: error || drivingError || allError,
            fetching: fetching || drivingFetching || allFetching,
        }),
        [
            usingAll,
            allData,
            usingDriving,
            drivingData,
            data,
            error,
            drivingError,
            allError,
            fetching,
            drivingFetching,
            allFetching,
        ],
    )

    // Refetch if initial pageSize changes after initial fetch happens
    // Sometimes the page size is not known until optimizely flags are loaded
    useEffect(() => {
        if (initialParams?.pageSize) {
            setParams((currParams) => ({ ...currParams, pageSize: initialParams.pageSize }))
            refetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialParams?.pageSize, setParams])

    const val = useMemo(() => {
        return {
            state,
            dispatch: {
                setStartDate,
                setEndDate,
            },
        }
    }, [state, setStartDate, setEndDate])

    return <Provider value={val}>{children}</Provider>
}

export const serverSideRead = serverSideQuery<ProductionPage>(PRODUCTIONS_ENDPOINT)
export default context
