import dynamic from 'next/dynamic'

/**
 * This component should be happy with SSR, but there's a long-standing problem
 * with local storage and NextJS resulting in hydration errors. Possibly related:
 * https://github.com/juliencrn/usehooks-ts/issues/90
 * https://github.com/streamich/react-use/issues/702
 */

const DynamicComponent = dynamic(() => import('./component'), {
    ssr: false,
})

export default DynamicComponent
