export const afcTeams = [
    {
        abbreviation: 'BAL',
        name: 'Baltimore Ravens',
        url: '/baltimore-ravens-tickets--sports-nfl-football/performer/60',
        primaryColor: '#241773',
        secondaryColor: '#9E7C0C',
    },
    {
        abbreviation: 'BUF',
        name: 'Buffalo Bills',
        url: '/buffalo-bills-tickets--sports-nfl-football/performer/128',
        primaryColor: '#00338D',
        secondaryColor: '#C60C30',
    },
    {
        abbreviation: 'CLE',
        name: 'Cleveland Browns',
        url: '/cleveland-browns-tickets--sports-nfl-football/performer/181',
        primaryColor: '#311D00',
        secondaryColor: '#FF3C00',
    },
    {
        abbreviation: 'CIN',
        name: 'Cincinnati Bengals',
        url: '/cincinnati-bengals-tickets--sports-nfl-football/performer/172',
        primaryColor: '#FB4F14',
        secondaryColor: '#000000',
    },
    {
        abbreviation: 'DEN',
        name: 'Denver Broncos',
        url: '/denver-broncos-tickets--sports-nfl-football/performer/234',
        primaryColor: '#FB4F14',
        secondaryColor: '#002244',
    },
    {
        abbreviation: 'HOU',
        name: 'Houston Texans',
        url: '/houston-texans-tickets--sports-nfl-football/performer/2559',
        primaryColor: '#03202F',
        secondaryColor: '#A71930',
    },
    {
        abbreviation: 'IND',
        name: 'Indianapolis Colts',
        url: '/indianapolis-colts-tickets--sports-nfl-football/performer/370',
        primaryColor: '#002C5F',
        secondaryColor: '#A2AAAD',
    },
    {
        abbreviation: 'JAC',
        name: 'Jacksonville Jaguars',
        url: '/jacksonville-jaguars-tickets--sports-nfl-football/performer/381',
        primaryColor: '#101820',
        secondaryColor: '#D7A22A',
    },
    {
        abbreviation: 'KC',
        name: 'Kansas City Chiefs ',
        url: '/kansas-city-chiefs-tickets--sports-nfl-football/performer/427',
        primaryColor: '#E31837',
        secondaryColor: '#FFB81C',
    },
    {
        abbreviation: 'LV',
        name: 'Las Vegas Raiders ',
        url: '/las-vegas-raiders-tickets--sports-nfl-football/performer/626',
        primaryColor: '#000000',
        secondaryColor: '#A5ACAF',
    },
    {
        abbreviation: 'LAC',
        name: 'Los Angeles Chargers ',
        url: '/los-angeles-chargers-tickets--sports-nfl-football/performer/755',
        primaryColor: '#0080C6',
        secondaryColor: '#FFC20E',
    },
    {
        abbreviation: 'MIA',
        name: 'Miami Dolphins',
        url: '/miami-dolphins-tickets--sports-nfl-football/performer/532',
        primaryColor: '#008E97',
        secondaryColor: '#FC4C02',
    },
    {
        abbreviation: 'NE',
        name: 'New England Patriots ',
        url: '/new-england-patriots-tickets--sports-nfl-football/performer/592',
        primaryColor: '#002244',
        secondaryColor: '#FC4C02',
    },
    {
        abbreviation: 'NYJ',
        name: 'New York Jets ',
        url: '/new-york-jets-tickets--sports-nfl-football/performer/601',
        primaryColor: '#125740',
        secondaryColor: '#000000',
    },
    {
        abbreviation: 'PIT',
        name: 'Pittsburgh Steelers',
        url: '/pittsburgh-steelers-tickets--sports-nfl-football/performer/686',
        primaryColor: '#FFB612',
        secondaryColor: '#101820',
    },
    {
        abbreviation: 'TEN',
        name: 'Tennessee Titans',
        url: '/tennessee-titans-tickets--sports-nfl-football/performer/848',
        primaryColor: '#0C2340',
        secondaryColor: '#4B92DB',
    },
]

export const nfcTeams = [
    {
        abbreviation: 'ARI',
        name: 'Arizona Cardinals',
        url: '/arizona-cardinals-tickets--sports-nfl-football/performer/40',
        primaryColor: '#97233F',
        secondaryColor: '#000000',
    },
    {
        abbreviation: 'ATL',
        name: 'Atlanta Falcons',
        url: '/atlanta-falcons-tickets--sports-nfl-football/performer/51',
        primaryColor: '#A71930',
        secondaryColor: '#000000',
    },
    {
        abbreviation: 'CAR',
        name: 'Carolina Panthers',
        url: '/carolina-panthers-tickets--sports-nfl-football/performer/144',
        primaryColor: '#0085CA',
        secondaryColor: '#101820',
    },
    {
        abbreviation: 'CHI',
        name: 'Chicago Bears',
        url: '/chicago-bears-tickets--sports-nfl-football/performer/159',
        primaryColor: '#0B162A',
        secondaryColor: '#C83803',
    },
    {
        abbreviation: 'DAL',
        name: 'Dallas Cowboys',
        url: '/dallas-cowboys-tickets--sports-nfl-football/performer/214',
        primaryColor: '#041E42',
        secondaryColor: '#869397',
    },
    {
        abbreviation: 'DET',
        name: 'Detroit Lions',
        url: '/detroit-lions-tickets--sports-nfl-football/performer/238',
        primaryColor: '#0076B6',
        secondaryColor: '#B0B7BC',
    },
    {
        abbreviation: 'GB',
        name: 'Green Bay Packers',
        url: '/green-bay-packers-tickets--sports-nfl-football/performer/339',
        primaryColor: '#203731',
        secondaryColor: '#FFB612',
    },
    {
        abbreviation: 'LAR',
        name: 'Los Angeles Rams',
        url: '/los-angeles-rams-tickets--sports-nfl-football/performer/808',
        primaryColor: '#003594',
        secondaryColor: '#FFA300',
    },
    {
        abbreviation: 'MIN',
        name: 'Minnesota Vikings',
        url: '/minnesota-vikings-tickets--sports-nfl-football/performer/555',
        primaryColor: '#4F2683',
        secondaryColor: '#FFC62F',
    },
    {
        abbreviation: 'NO',
        name: 'New Orleans Saints',
        url: '/new-orleans-saints-tickets--sports-nfl-football/performer/597',
        primaryColor: '#D3BC8D',
        secondaryColor: '#101820',
    },
    {
        abbreviation: 'NYG',
        name: 'New York Giants',
        url: '/new-york-giants-tickets--sports-nfl-football/performer/599',
        primaryColor: '#0B2265',
        secondaryColor: '#A71930',
    },
    {
        abbreviation: 'PHI',
        name: 'Philadelphia Eagles',
        url: '/philadelphia-eagles-tickets--sports-nfl-football/performer/669',
        primaryColor: '#004C54',
        secondaryColor: '#A5ACAF',
    },
    {
        abbreviation: 'SF',
        name: 'San Francisco 49ers',
        url: '/san-francisco-49ers-tickets--sports-nfl-football/performer/758',
        primaryColor: '#AA0000',
        secondaryColor: '#B3995D',
    },
    {
        abbreviation: 'SEA',
        name: 'Seattle Seahawks',
        url: '/seattle-seahawks-tickets--sports-nfl-football/performer/772',
        primaryColor: '#002244',
        secondaryColor: '#69BE28',
    },
    {
        abbreviation: 'TAM',
        name: 'Tampa Bay Buccaneers',
        url: '/tampa-bay-buccaneers-tickets--sports-nfl-football/performer/839',
        primaryColor: '#D50A0A',
        secondaryColor: '#0A0A08',
    },
    {
        abbreviation: 'WAS',
        name: 'Washington Commanders',
        url: '/washington-commanders-tickets--sports-nfl-football/performer/925',
        primaryColor: '#5A1414',
        secondaryColor: '#FFB612',
    },
]
