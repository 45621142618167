import dayjs from 'dayjs'

import { formatDateWithPattern } from '@/utils/dates'

export type DateFilter = {
    value: string
    label: string
}

const datePattern = 'YYYY-MM-DD'
const nextSevenDaysString = formatDateWithPattern(dayjs().add(7, 'day'), datePattern)
const thisWeekendString = formatDateWithPattern(dayjs().day(7), datePattern)
const thisWeekString = formatDateWithPattern(dayjs().day(7), datePattern)
const thisMonthString = dayjs().endOf('month').format(datePattern)
const nextThirtyDaysString = formatDateWithPattern(dayjs().add(30, 'day'), datePattern)
const nextSixtyDaysString = formatDateWithPattern(dayjs().add(60, 'day'), datePattern)

export const dateFilters: Record<string, DateFilter> = {
    thisWeekend: {
        value: thisWeekendString,
        label: 'This Weekend',
    },
    thisWeek: {
        value: thisWeekString,
        label: 'This Week',
    },
    thisMonth: {
        value: thisMonthString,
        label: 'This Month',
    },
    nextSevenDays: {
        value: nextSevenDaysString,
        label: 'Next 7 Days',
    },
    nextThirtyDays: {
        value: nextThirtyDaysString,
        label: 'Next 30 Days',
    },
    nextSixtyDays: {
        value: nextSixtyDaysString,
        label: 'Next 60 Days',
    },
}

export const todayString = formatDateWithPattern(dayjs(), datePattern)
export const oneWeekFromTodayString = formatDateWithPattern(dayjs().add(7, 'day'), 'YYYY-MM-DD')
export const thisFridayString = formatDateWithPattern(dayjs().day(5), datePattern)
