import { CmsHomeDataType } from '@/context/cms-home/types'

export type Polygon = number[][]

export type GeolocatedHeroData = {
    name: string
    secondPerformerName?: string
    imagePath?: string
    eventIds: number[]
    polygon: Polygon
}

export enum HomepageRedesignVariable {
    GET_IN_PRICING = 'getinPricing',
    EXPOSED_FILTERS = 'exposedFilters',
    SCROLLABLE_ARROWS = 'scrollableArrows',
    EXPANDED_LAYOUT = 'expandedLayout',
    EVENTS_UNDER_100 = 'eventsUnder100',
    ABOVE_THE_FOLD = 'aboveTheFold',
    TILE_CARD_SIZE = 'tileCardSize',
    BROWSE_TOP_CATEGORIES = 'browseCategories',
}

export type HomepageRedesignVariableType = {
    [key in HomepageRedesignVariable]: boolean
}

export interface Props {
    initialCmsHomeData?: CmsHomeDataType
}

export type CTA = {
    href: string
    text: string
}
