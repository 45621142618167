import React from 'react'

import { Hero } from '@vividseats/vivid-ui-kit'
import type { NextPage } from 'next'
import Link from 'next/link'

import { PATHS, REWARDS_DISCLAIMER_TEXT } from '@/constants'

import styles from './styles.module.scss'

const IMAGE_SRC_MOBILE =
    'https://media.vsstatic.com/image/upload/f_auto,q_auto/hero/homepage/loyalty-banner/2023/buyer-guarantee/s-sub-banner.jpg'
const IMAGE_SRC_DESKTOP =
    'https://media.vsstatic.com/image/upload/f_auto,q_auto/hero/homepage/loyalty-banner/2023/buyer-guarantee/xl-sub-banner.jpg'
const IMAGE_SRC_TABLET =
    'https://media.vsstatic.com/image/upload/f_auto,q_auto/hero/homepage/loyalty-banner/2023/buyer-guarantee/l-sub-banner.jpg'

const TITLE = 'Reliable & Rewarding Tickets '

const FreeTickets: NextPage = () => {
    const body = (
        <span>
            {'We have a '}
            <Link href={PATHS.BUYER_GUARANTEE}>100% Buyer Guarantee</Link>
            <br className={styles.mobileBreak} />
            {' + the only '}
            <Link href={PATHS.REWARDS}>ticket rewards program</Link>
            {' around.'}
            <br />
            {"So, grab your seats, they're guaranteed - just like your 11th ticket on us*."}
        </span>
    )

    return (
        <div data-testid="free-tickets-hero" className={styles.heroWrapper}>
            <Hero
                mobileImage={IMAGE_SRC_MOBILE}
                desktopImage={IMAGE_SRC_DESKTOP}
                tabletImage={IMAGE_SRC_TABLET}
                titleProps={{ label: TITLE, size: 'title-xxl', as: 'h2' }}
                subtitleTop={{ content: body }}
                classes={{
                    root: styles.container,
                    body: styles.body,
                    title: styles.title,
                    subtitle: styles.subtitle,
                    button: styles.button,
                    image: styles.image,
                    bottomText: styles.bottomText,
                }}
                withGradient={false}
                bottomText={REWARDS_DISCLAIMER_TEXT}
            />
        </div>
    )
}

export default FreeTickets
