import React from 'react'

import { ArrowForwardIcon, Column, Grid, Link, Row, Text } from '@vividseats/vivid-ui-kit'
import NextLink from 'next/link'

import { PATHS } from '@/constants'
import { BUYER_GUARANTEE_EXCLUDED_COUNTRIES } from '@/context/internationalization/constants'
import { useIsCountryFeatured } from '@/context/location/hooks/use-is-country-featured'
import { useGeneralAdmissionUKFeature } from '@/optimizely/features/internationalization/hooks/general-admission-uk'

import styles from './styles.module.scss'

const BuyerGuarantee: React.FC = () => {
    const [isGeneralAdmissionUKEnabled] = useGeneralAdmissionUKFeature()
    const hide100 = useIsCountryFeatured(BUYER_GUARANTEE_EXCLUDED_COUNTRIES) && isGeneralAdmissionUKEnabled
    const buyerGuaranteeText = hide100 ? 'Buyer Guarantee' : '100% Buyer Guarantee'

    return (
        <Grid className={styles.grid}>
            <Row>
                <Column className={styles.columnItems}>
                    <Row className={styles.itemsContainer}>
                        <Column className={styles.item} mb={3}>
                            <Text className={styles.itemText} altFont data-testid="buyer-guarantee-text">
                                {buyerGuaranteeText}
                            </Text>
                            <Text size="lg" altFont>
                                Designed to give you peace of mind.
                            </Text>
                        </Column>
                        <Column className={styles.item} mb={3}>
                            <Text className={styles.itemText} altFont>
                                Safe &amp; secure transactions
                            </Text>
                            <Text size="lg" altFont>
                                Your private and personal information should remain private and personal.
                            </Text>
                        </Column>
                        <Column className={styles.item} mb={3}>
                            <Text className={styles.itemText} altFont>
                                Full-service customer care
                            </Text>
                            <Text size="lg" altFont>
                                You deserve hassle-free assistance from start to finish.
                            </Text>
                        </Column>
                    </Row>
                    <Row>
                        <Column>
                            <Link
                                LinkComponent={NextLink}
                                href={PATHS.BUYER_GUARANTEE}
                                target="_self"
                                importance="none"
                                legacyBehavior
                            >
                                <div className={styles.linkDetail}>
                                    <Text size="lg" className={styles.linkText}>
                                        Learn more about our guarantee
                                    </Text>
                                    <ArrowForwardIcon fontSize="1.5rem" />
                                </div>
                            </Link>
                        </Column>
                    </Row>
                </Column>
            </Row>
        </Grid>
    )
}

export default BuyerGuarantee
