import React from 'react'

import { Typography } from '@mui/material'

import ButtonWithCustomChildren from './button-with-custom-children'
import { TYPOGRAPHY_VARIANT_BY_SIZE } from './constants'
import type { ButtonProps } from './types'

// TODO: consolidate Button & ButtonWithCustomChildren?
// yes please! @see https://vividseats.atlassian.net/browse/BWCT-915

const Button = ({ children, size, ...props }: ButtonProps) => {
    const formattedChildren = <Typography variant={TYPOGRAPHY_VARIANT_BY_SIZE[size]}>{children}</Typography>

    return (
        <ButtonWithCustomChildren size={size} {...props}>
            {formattedChildren}
        </ButtonWithCustomChildren>
    )
}

export default Button
