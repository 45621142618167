import React, { $FC, createContext, useMemo } from 'react'

import type { AnalyticsContextType, AnalyticsProviderProps } from './types'

const defaultContextValue: AnalyticsContextType = {
    state: undefined as any,
}

const AnalyticsContext = createContext<AnalyticsContextType>(defaultContextValue)
const { Provider } = AnalyticsContext

export const AnalyticsProvider: $FC<AnalyticsProviderProps> = ({ children, initialProps }) => {
    const contextValue: AnalyticsContextType = useMemo(() => ({ state: initialProps }), [initialProps])

    return <Provider value={contextValue}>{children}</Provider>
}

export default AnalyticsContext
