import { afcTeams, nfcTeams } from '@/optimizely/nfl-home-page-feature/components/nfl-list/constants'

import heroPolygons from '../../hooks/hero-polygons.json'
import { CTA, GeolocatedHeroData } from '../../types'

import { BACKGROUND_IMAGE_SIZE } from './types'
const TEAMS = [...afcTeams, ...nfcTeams]

const getNflPerformerUrl = (eventId: number): string | undefined => {
    if (!eventId) return '/nfl'
    const team = TEAMS.find((team) => team.url.endsWith(eventId.toString()))

    return team?.url
}

const getNflPerformerName = (eventId: number): string | undefined => {
    const polygon = heroPolygons.find((polygon) => polygon.eventIds.includes(eventId))

    return polygon?.name
}

const getNflSecondPerformerName = (eventId: number): string | undefined => {
    const polygons = heroPolygons.filter((polygon) => polygon.eventIds.includes(eventId))

    return polygons.find((polygon) => polygon.eventIds.length > 1 && polygon.eventIds.includes(eventId))
        ?.secondPerformerName
}

const getNflBackgroundImage = (geolocatedData: GeolocatedHeroData | undefined): Map<string, string> => {
    let name = ''
    let imagePath: string | undefined
    let eventIds: number[] = []
    if (geolocatedData) {
        name = geolocatedData.name
        eventIds = geolocatedData.eventIds
        imagePath = geolocatedData.imagePath
    }

    let image: string | undefined = undefined
    if (!eventIds.length) {
        image = 'general'
    } else {
        image = eventIds.length > 1 ? imagePath : name.toLowerCase().replace(' ', '-')
    }
    const sizeMap = new Map()

    const sizes = [
        BACKGROUND_IMAGE_SIZE.SM,
        BACKGROUND_IMAGE_SIZE.MD,
        BACKGROUND_IMAGE_SIZE.LG,
        BACKGROUND_IMAGE_SIZE.XL,
    ]

    const path =
        'https://media.vsstatic.com/image/upload/f_auto,q_auto,dpr_auto,w_auto,g_face/v3/hero/homepage/2023/nfl-schedule-release/'

    sizes.forEach((size) => {
        sizeMap.set(size, `${path}/${size}-background-slim-${image}.jpg`)
    })

    return sizeMap
}

const getNflSecondCta = (geolocatedData: GeolocatedHeroData, isMobile: boolean): CTA | undefined => {
    const { eventIds } = geolocatedData
    if (eventIds.length < 2) return undefined

    const href = getNflPerformerUrl(eventIds[1])
    const performerName = getNflSecondPerformerName(eventIds[1])
    const text = isMobile ? `${performerName} Tickets` : `Score ${performerName} Tickets`

    if (!href || !text) return

    return {
        href,
        text,
    }
}

export { getNflPerformerUrl, getNflBackgroundImage, getNflPerformerName, getNflSecondCta }
