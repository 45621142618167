import React from 'react'

import { Pill, Text } from '@vividseats/vivid-ui-kit'

import { useSellingFastDealsAvailableConcertPerformerFeature } from '@/optimizely/features/selling-fast-deals-available-concert-performer-feature/hooks'
import { useSellingFastDealsAvailableSportsHomePerformerFeature } from '@/optimizely/features/selling-fast-deals-available-sports-home-performer-feature/hooks'
import { useSellingFastDealsAvailableSportsPerformerFeature } from '@/optimizely/features/selling-fast-deals-available-sports-performer-feature/hooks'
import { useSellingFastDealsAvailableTheatrePerformerFeature } from '@/optimizely/features/selling-fast-deals-available-theatre-performer-feature/hooks'

import styles from './styles.module.scss'

const LowPricesDealsAvailableBadge: React.FC = () => {
    const [, { badgeLabel: badgeLabelConcertPerformer }] = useSellingFastDealsAvailableConcertPerformerFeature()
    const [, { badgeLabel: badgeLabelTheatrePerformer }] = useSellingFastDealsAvailableTheatrePerformerFeature()
    const [, { badgeLabel: badgeLabelSportsPerformer }] = useSellingFastDealsAvailableSportsPerformerFeature()
    const [, { badgeLabel: badgeLabelSportsHomePerformer }] = useSellingFastDealsAvailableSportsHomePerformerFeature()

    return (
        <Pill size="lg" className={styles.lowPricesDealsAvailablePill} data-testid="low-prices-deals-available-pill">
            <Text altFont weight="regular-bold">
                <span aria-label="money bag" role="img">
                    💰
                </span>
                {` ${
                    badgeLabelSportsPerformer ||
                    badgeLabelSportsHomePerformer ||
                    badgeLabelTheatrePerformer ||
                    badgeLabelConcertPerformer
                }`}
            </Text>
        </Pill>
    )
}

export default LowPricesDealsAvailableBadge
