import React, { FC, memo, useContext, useMemo, useState, useEffect } from 'react'

import { withOptimizely, WithOptimizelyProps } from '@optimizely/react-sdk'
import classnames from 'classnames'

import SharedTopPicks from '@/components/shared/top-picks'
import TopProductionsNearYouContext from '@/context/top-productions-near-you'
import { AnalyticsSubIds } from '@/context/utils/analytics'
import { useTrackViewProductionList } from '@/hooks/use-analytics'
import useWindowSize from '@/hooks/use-window-size'
import { OPTIMIZELY_EVENT } from '@/optimizely/constants'
import type { Production } from '@/types/app-types'
import { PageType } from '@/types/app-types'
import { trackHomePageEvent } from '@/utils/analytics'
import { ClickLocation } from '@/utils/analytics/types'

import { HomepageRedesignVariable } from '../../types'
import { getHomepageRedesignVariable } from '../../utils'

import styles from './styles.module.scss'
import { thisFridayString, todayString, dateFilters } from './utils'

export interface TopPicksProps extends WithOptimizelyProps {
    title?: string
    shouldShowUserLocation?: boolean
    shouldShowEventsUnder100?: boolean
    trackingCarouselLocation?: number
    trackingClickLocation: ClickLocation
    trackingViewProductionList: AnalyticsSubIds
    optimizelyTrackEvent?: string
}

export const TopPicks: FC<TopPicksProps> = ({
    title,
    shouldShowUserLocation = true,
    shouldShowEventsUnder100,
    optimizely,
    trackingCarouselLocation,
    trackingClickLocation,
    trackingViewProductionList,
    optimizelyTrackEvent = OPTIMIZELY_EVENT.topPickClick,
}) => {
    const {
        state,
        dispatch: { setStartDate, setEndDate },
    } = useContext(TopProductionsNearYouContext)
    const [activeDateFilter, setActiveDateFilter] = useState<string | undefined>(undefined)
    const shouldShowLoader = (state.fetching ?? true) || state?.data?.total === 0
    const analyticsProductions: Production[] = useMemo(() => state.data?.items ?? [], [state.data?.items])
    const { type } = useWindowSize()
    const {
        [HomepageRedesignVariable.GET_IN_PRICING]: shouldShowMinPrice,
        [HomepageRedesignVariable.SCROLLABLE_ARROWS]: shouldShowScrollableArrows,
        [HomepageRedesignVariable.EXPANDED_LAYOUT]: shouldShowExpandedLayout,
        [HomepageRedesignVariable.ABOVE_THE_FOLD]: shouldAboveTheFold,
        [HomepageRedesignVariable.TILE_CARD_SIZE]: shouldTileCard,
        [HomepageRedesignVariable.EXPOSED_FILTERS]: shouldExposeFilter,
    } = getHomepageRedesignVariable(optimizely, type)

    const isFirstTopPicksCarousel = trackingCarouselLocation === 1
    const shouldShowFilter = isFirstTopPicksCarousel && shouldExposeFilter

    useTrackViewProductionList(!shouldShowLoader, analyticsProductions, trackingViewProductionList)

    useEffect(() => {
        // when showing filter, request productions with no end date (i.e. "Any Dates" filter)
        if (shouldShowFilter) {
            setEndDate(undefined)
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [shouldShowFilter])

    const handleSetDateFilter = (filter: string | undefined) => {
        const selectedDateFilter = filter ? dateFilters[filter] : undefined
        const startDate = filter === 'thisWeekend' ? thisFridayString : todayString
        const endDate = selectedDateFilter?.value

        trackHomePageEvent({
            click_location: ClickLocation.HOME_PAGE_TOP_PICKS,
            click_text: selectedDateFilter?.label.toLowerCase() || 'any dates',
        })

        if (filter === activeDateFilter) {
            setActiveDateFilter(undefined)
            setStartDate(todayString)
            setEndDate(undefined)
        } else {
            setActiveDateFilter(filter)
            setStartDate(startDate)
            setEndDate(endDate)
        }
    }

    const handleProductionClick = () => {
        optimizely?.track(optimizelyTrackEvent)
    }

    const filterComponent = shouldShowFilter ? (
        <ul className={styles.filterList} data-testid="date-filter">
            <li>
                <button
                    className={classnames(styles.filterButton, !activeDateFilter && styles.active)}
                    onClick={() => handleSetDateFilter(undefined)}
                >
                    Any Dates
                </button>
            </li>
            {Object.keys(dateFilters).map((filter) => (
                <li key={filter}>
                    <button
                        className={classnames(styles.filterButton, activeDateFilter === filter && styles.active)}
                        onClick={() => handleSetDateFilter(filter)}
                    >
                        {dateFilters[filter].label}
                    </button>
                </li>
            ))}
        </ul>
    ) : null

    return (
        <SharedTopPicks
            shouldShowLoader={shouldShowLoader}
            productions={state.data?.items ?? []}
            trackingCarouselLocation={trackingCarouselLocation}
            showMoreDates
            filterComponent={filterComponent}
            shouldShowMinPrice={shouldShowMinPrice}
            shouldShowScrollableArrows={shouldShowEventsUnder100 ? false : shouldShowScrollableArrows}
            shouldShowExpandedLayout={shouldShowEventsUnder100 ? false : shouldShowExpandedLayout}
            shouldAboveTheFold={shouldAboveTheFold}
            shouldTileCard={shouldShowEventsUnder100 ? false : shouldTileCard}
            shouldShowEventsUnder100={shouldShowEventsUnder100}
            classes={{ headerContainer: styles.headerContainer }}
            onProductionClick={handleProductionClick}
            activeDateFilter={activeDateFilter}
            shouldShowUserLocation={shouldShowUserLocation}
            title={title ? title : shouldShowFilter ? 'Our top picks' : undefined}
            trackingClickLocation={trackingClickLocation}
            trackingPageType={PageType.Home}
        />
    )
}

export default memo(withOptimizely(TopPicks))
