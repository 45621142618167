import React from 'react'

import { getUserLocationCookieValue, useDesktopMediaQuery } from '@/utils'

import { useNflHomepageSuperbowlBanner } from '../hooks/use-nfl-homepage-superbowl-banner'

import {
    GAMECENTER_IMG_LEGAL_LINK,
    GAMECENTER_IMG_DESTINATION_LINK,
    GAMECENTER_IMG_SRC_MOBILE,
    GAMECENTER_IMG_SRC_TABLET,
    GAMECENTER_IMG_SRC_DESKTOP,
    GAMECENTER_IMG_SRC_DESKTOP_XL,
    GAMECENTER_IMG_ALT_TEXT,
    MAXIM_IMG_DESTINATION_LINK,
    MAXIM_IMG_SRC_MOBILE,
    MAXIM_IMG_SRC_TABLET,
    MAXIM_IMG_SRC_DESKTOP,
    MAXIM_IMG_SRC_DESKTOP_XL,
    MAXIM_IMG_ALT_TEXT,
} from './constants'
import styles from './styles.module.scss'

export const GamerCenterLegalLink: React.VFC = () => {
    return (
        <div className={styles.gameCenterLegalText} data-testid="game-center-legal-text">
            See
            <a href={GAMECENTER_IMG_LEGAL_LINK} target="_blank" rel="noreferrer">
                {` ${GAMECENTER_IMG_LEGAL_LINK} `}
            </a>
            for official rules.
        </div>
    )
}

export const GameCenterBanner: React.VFC = () => {
    return (
        <div className={styles.gamerCenterBannerContainer} data-testid="game-center-banner">
            <a
                href={GAMECENTER_IMG_DESTINATION_LINK}
                target="_blank"
                rel="noreferrer"
                className={styles.gameCenterBannerLink}
            >
                <picture data-testid="picture-wrapper">
                    <source srcSet={GAMECENTER_IMG_SRC_MOBILE} media="(max-width: 374px)" />
                    <source srcSet={GAMECENTER_IMG_SRC_TABLET} media="(max-width: 767px)" />
                    <source srcSet={GAMECENTER_IMG_SRC_DESKTOP} media="(max-width: 1023px)" />
                    <img src={GAMECENTER_IMG_SRC_DESKTOP_XL} alt={GAMECENTER_IMG_ALT_TEXT} />
                </picture>
                <div className={styles.gameCenterText}>
                    Win FREE Tickets to <br /> The Big Game
                </div>
            </a>
            <GamerCenterLegalLink />
        </div>
    )
}

export const MaximBanner: React.VFC = () => {
    const isDesktop = useDesktopMediaQuery()

    return (
        <div className={styles.maxmimBannerContainer} data-testid="maxim-banner">
            <a href={MAXIM_IMG_DESTINATION_LINK} target="_blank" rel="noreferrer" className={styles.nflBannerLink}>
                <picture data-testid="picture-wrapper">
                    <source srcSet={MAXIM_IMG_SRC_MOBILE} media="(max-width: 374px)" />
                    <source srcSet={MAXIM_IMG_SRC_TABLET} media="(max-width: 767px)" />
                    <source srcSet={MAXIM_IMG_SRC_DESKTOP} media="(max-width: 1023px)" />
                    <img src={MAXIM_IMG_SRC_DESKTOP_XL} alt={MAXIM_IMG_ALT_TEXT} />
                </picture>
                <div className={styles.maximText} data-testid="maxim-text">
                    #1 VIP Party of {!!isDesktop && <br />} Big Game Weekend!
                </div>
            </a>
        </div>
    )
}

const NflHomePageBanner: React.VFC = () => {
    const [isNflHomepageSuperbowlBannerEnable, nflHomepageSuperbowlBannerVariables] = useNflHomepageSuperbowlBanner()
    const isAllBannerDisabled =
        !nflHomepageSuperbowlBannerVariables['gameCenter'] && !nflHomepageSuperbowlBannerVariables['maxim']

    if (!isNflHomepageSuperbowlBannerEnable || isAllBannerDisabled) return <></>

    const userLocationCookie = getUserLocationCookieValue()
    const isGameCenter = userLocationCookie?.countryCode === 'US' && nflHomepageSuperbowlBannerVariables['gameCenter']

    return (
        <div className={styles.nflBannerContainer} data-testid="nfl-banner-container">
            {isGameCenter ? <GameCenterBanner /> : <MaximBanner />}
        </div>
    )
}

export default NflHomePageBanner
