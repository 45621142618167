import React from 'react'

import { Button as MuiButton, ButtonBase, styled } from '@mui/material'
import { Icon } from '@vividseats/vivid-ui-kit'

import { ICON_SIZE_BY_SIZE } from '../constants'
import type { ButtonBaseProps, ButtonProps } from '../types'

import styles from './styles.module.scss'

// TODO: not all Button variations set up yet;
//  return null for those variations for now + add variations as needed
// TODO: setup focus state

// TODO: setup text variant to work with icons
// TODO: consider using built in endIcon/startIcon as opposed to our setup of handling icons
// TODO: worth considering different approach to handling icons in general

// see src/design-system/themes/components.ts for default button styleOverrides
const StyledButton = styled(MuiButton, {
    shouldForwardProp: (prop) => prop !== 'activated' && prop !== 'isIcon' && prop !== 'alignment',
})<ButtonProps>(({ activated, color, isIcon, size, variant, theme, alignment }) => ({
    ...(activated && {
        ...(variant === 'outlined' && {
            ...(color === 'secondary' && {
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.secondary.dark,
                borderColor: theme.palette.secondary.dark,
            }),
            ...(color === 'primary' && {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.dark,
                borderColor: theme.palette.primary.dark,
            }),
        }),
        ...(variant === 'contained' && {
            ...(color === 'primary' && {
                backgroundColor: theme.palette.primary.darkest,
            }),
            ...(color === 'neutral' && {
                border: `0.0625rem solid ${theme.palette.neutral?.light}`,
                backgroundColor: theme.palette.neutral?.lighter,
                boxShadow: 'none',
            }),
        }),
    }),
    ...(size === 'small' && {
        ...(!isIcon && {
            paddingLeft: '0.75rem',
            paddingRight: '0.75rem',
        }),
        ...(isIcon && {
            paddingLeft: '0.25rem',
            paddingRight: '0.25rem',
        }),
    }),
    ...(size === 'medium' && {
        ...(!isIcon && {
            paddingLeft: '1rem',
            paddingRight: '1rem',
        }),
        ...(isIcon && {
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
        }),
    }),
    ...(size === 'large' && {
        ...(!isIcon && {
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem',
        }),
        ...(isIcon && {
            paddingLeft: '0.75rem',
            paddingRight: '0.75rem',
        }),
    }),
    justifyContent: alignment === 'left' ? 'flex-start' : undefined,
    whiteSpace: 'nowrap',
}))

const StyledBaseButton = styled(ButtonBase, {
    shouldForwardProp: (prop) => prop !== 'activated' && prop !== 'isIcon',
})<ButtonBaseProps>(({ activated, isIcon }) => ({
    ...(!isIcon && {
        ':hover': {
            textDecoration: 'underline',
        },
        ':active': {
            textDecoration: 'underline',
        },
        ...(activated && {
            textDecoration: 'underline',
        }),
    }),
}))

const ButtonWithCustomChildren = ({
    children,
    color,
    iconEnd,
    customIconEnd,
    size,
    variant,
    alignment = 'center',
    ...props
}: ButtonProps) => {
    if (color === 'neutral' && variant === 'text') {
        // as other colors are setup for the 'text' variant, we can drop the color condition
        return (
            <StyledBaseButton color={color} {...props}>
                {children}
                {!!iconEnd && (
                    <Icon type={iconEnd} size={ICON_SIZE_BY_SIZE[size]} className={styles.iconNegativeMargin} />
                )}
                {customIconEnd}
            </StyledBaseButton>
        )
    } else if (variant === 'outlined' || (variant === 'contained' && (color === 'primary' || color === 'neutral'))) {
        // once all non-text variations are set up (all colors for 'outlined' & 'contained' variants),
        // we can drop this conditional and return below by default;
        // the 'else if' statement here is just to prevent us from using buttons that haven't been set up yet.
        return (
            <StyledButton color={color} size={size} variant={variant} alignment={alignment} {...props}>
                {children}
                {!!iconEnd && (
                    <Icon type={iconEnd} size={ICON_SIZE_BY_SIZE[size]} className={styles.iconNegativeMargin} />
                )}
                {customIconEnd}
            </StyledButton>
        )
    }

    console.warn(`Button not yet set up for variant:${variant} and color: ${color}`)
    return null
}

export default ButtonWithCustomChildren
