import { useContext, useEffect } from 'react'

import AnalyticsContext from '@/context/analytics'
import { getAnalyticsID } from '@/context/utils/analytics'
import { PageType, Performer, Production, Venue } from '@/types/app-types'
import {
    trackViewProductionList,
    trackPageView as trackPageViewGA4,
    trackPerformerPageView,
    trackVenuePageView,
    trackMyAccountPageEvent,
} from '@/utils/analytics'
import { trackPageView as trackPageViewGA360 } from '@/utils/analytics/ga360'

import { MyAccountMetadata } from '../../components/layouts/account-layout/type'

import type { PageData } from './type'

export const useTrackViewProductionListWithAnalyticsId = (
    analyticsId: string | null,
    shouldTrack: boolean,
    productions: Production[],
): void => {
    useEffect(() => {
        if (shouldTrack) {
            trackViewProductionList({ item_list_name: analyticsId, productions })
        }
    }, [analyticsId, shouldTrack, productions])
}

export const useAnalytics = (...analyticsSubIds: string[]) => {
    const { state: analyticsState } = useContext(AnalyticsContext)
    const analyticsId = getAnalyticsID(analyticsState, ...analyticsSubIds)
    const analyticsPageType = analyticsState.pageType

    return { analyticsId, analyticsPageType }
}

export const useTrackViewProductionList = (
    shouldTrack: boolean,
    productions: Production[],
    ...analyticsSubIds: string[]
) => {
    const { analyticsId } = useAnalytics(...analyticsSubIds)
    useTrackViewProductionListWithAnalyticsId(analyticsId, shouldTrack, productions)
}

export const useTrackPageView = (pageType: PageType, pageData?: PageData) => {
    useEffect(() => {
        if (!pageType) return
        trackPageViewGA360() // universal call for every pages

        // pageData in this switch needs to assert type manually with 'as' to make it strongly typed. See "Discriminated Unions"
        switch (pageType) {
            case PageType.Performer:
            case PageType.MatchupPage:
            case PageType.CustomPage:
                trackPerformerPageView(PageType.Performer, pageData as Performer[])
                break
            case PageType.Venue:
                trackVenuePageView(pageData as Venue)
                break
            case PageType.MyAccount:
                trackMyAccountPageEvent(pageData as MyAccountMetadata)
                break
            default:
                trackPageViewGA4(pageType, pageData as any)
                break
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [pageType])
}
