import dayjs from 'dayjs'

export enum PulsingBadgeLabel {
    Onsale = 'Newly Released',
    Presale = 'Presale Now',
}

export const getPulsingBadgeLabel = (onsaleDate?: string, presale1Date?: string, presale2Date?: string): string => {
    const today = dayjs()
    const onsaleDateObject = onsaleDate ? dayjs(onsaleDate) : undefined
    const isBeforeOnsale = onsaleDateObject && today.isBefore(onsaleDateObject, 'day')
    const isTodayAfterPresale1 = presale1Date && today.isAfter(dayjs(presale1Date), 'day')
    const isTodayAfterPresale2 = presale2Date && today.isAfter(dayjs(presale2Date), 'day')
    const isOnsale = onsaleDateObject && onsaleDateObject.isSame(today, 'day')
    const isPresale = isBeforeOnsale && (isTodayAfterPresale1 || isTodayAfterPresale2)

    if (isOnsale) return PulsingBadgeLabel.Onsale
    if (isPresale) return PulsingBadgeLabel.Presale
    return ''
}
