import { useContext } from 'react'

import { useRouter } from 'next/router'
import { getSelectorsByUserAgent } from 'react-device-detect'

import userDataContext from '@/context/user-data'
import { useDesktopMediaQuery } from '@/utils'

const PATH_WHITELIST = ['/performer/', '/venue/']

export const useShouldOpenProductionInNewTab = () => {
    const {
        state: { userAgent },
    } = useContext(userDataContext)

    const router = useRouter()

    const { isDesktop: isDesktopServerSide } = userAgent ? getSelectorsByUserAgent(userAgent) : { isDesktop: false }
    const isDesktopClientSide = useDesktopMediaQuery()

    const isWhitelistedPage = PATH_WHITELIST.some((path) => router?.pathname.includes(path))

    return isWhitelistedPage && (isDesktopClientSide || isDesktopServerSide)
}
