import React, { FC } from 'react'

import { withOptimizely, WithOptimizelyProps } from '@optimizely/react-sdk'
import { Box, Text } from '@vividseats/vivid-ui-kit'

import { useNflHomePageFeature } from '../../hooks/use-nfl-home-page-feature'

import NflTeam from './components/nfl-team'
import { afcTeams, nfcTeams } from './constants'
import styles from './styles.module.scss'

export const NflList: FC<WithOptimizelyProps> = ({ optimizely }) => {
    const [, variables] = useNflHomePageFeature(optimizely)
    const teams = [...afcTeams, ...nfcTeams].sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))

    return (
        <Box className={styles.nflList} mobilePadding={5} tabletPadding={9} desktopPadding={7} data-testid="nfl-list">
            <Text className={styles.title} altFont size="xl" weight="extra-bold">
                {variables.title || 'NFL Teams'}
            </Text>
            <Box display="flex" wrap justify="between" marginBottom={7}>
                {teams.map((props, i) => {
                    return (
                        <Box key={props.abbreviation} className={styles.nflTeam} data-testid={`nfl-team-${i}`}>
                            <NflTeam {...props} />
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}

export default withOptimizely(NflList)
