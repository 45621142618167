import { selector } from 'recoil'

import { firstPageAtom } from '../atoms/isFirstPageInSessionAtom'

export const isFirstPageSelector = selector({
    key: 'isFirstPageState', // unique ID (with respect to other atoms/selectors)
    get: ({ get }) => {
        if (typeof window !== 'undefined') {
            const firstPage = get(firstPageAtom)
            return firstPage?.url === window.location.href
        }
    },
})
