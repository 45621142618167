import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import type { WithOptimizelyProps } from '@optimizely/react-sdk'
import { withOptimizely } from '@optimizely/react-sdk'
import { backdropContext, Box } from '@vividseats/vivid-ui-kit'
import classNames from 'classnames'
import dayjs from 'dayjs'
import type { GetServerSideProps, NextPage } from 'next'

import Footer from '@/components/layouts/main-layout/components/footer'
import Header from '@/components/layouts/main-layout/components/header'
import AccessibilityNavigation from '@/components/shared/accessibility-navigation'
import JsonLd from '@/components/shared/jsonld'
import Meta from '@/components/shared/seo-meta'
import { ACCESSIBILITY_IDS, MAX_NEARBY_DISTANCE, URLS } from '@/constants'
import { AnalyticsProvider } from '@/context/analytics'
import { CmsHomeProvider, serverSideRead as cmsServerSideRead } from '@/context/cms-home'
import type { CmsHomeDataType } from '@/context/cms-home/types'
import { HeaderStyleProvider } from '@/context/header'
import { BUYER_GUARANTEE_EXCLUDED_COUNTRIES } from '@/context/internationalization/constants'
import InternationalizationContext from '@/context/internationalization/context'
import UserLocationContext from '@/context/location/context'
import { useIsCountryFeatured } from '@/context/location/hooks/use-is-country-featured'
import { TopProductionsNearYouProvider } from '@/context/top-productions-near-you'
import { AnalyticsIds, AnalyticsSubIds } from '@/context/utils/analytics'
import { useTrackPageView } from '@/hooks/use-analytics'
import { useForceUpdate } from '@/hooks/use-force-update'
import useWindowSize from '@/hooks/use-window-size'
import { OPTIMIZELY_EVENT } from '@/optimizely/constants'
import { useGeneralAdmissionUKFeature } from '@/optimizely/features/internationalization/hooks/general-admission-uk'
import { useLocalizedCurrencyFeature } from '@/optimizely/features/internationalization/hooks/localized-currency'
import NflHomePageBanner from '@/optimizely/features/nfl-homepage-superbowl-banner/components'
import { useNflHomepageSuperbowlBanner } from '@/optimizely/features/nfl-homepage-superbowl-banner/hooks/use-nfl-homepage-superbowl-banner'
import StickySearchBarHeader from '@/optimizely/features/search-bar-feature/components/sticky-search-bar-header'
import { useSearchBarFeature } from '@/optimizely/features/search-bar-feature/hooks/use-search-bar-feature'
import { useHomePageDynamicContent } from '@/optimizely/home-page-dynamic-content/hooks/use-home-page-dynamic-content'
import NflList from '@/optimizely/nfl-home-page-feature/components/nfl-list'
import { useNflHomePageFeature } from '@/optimizely/nfl-home-page-feature/hooks/use-nfl-home-page-feature'
import type { UserLocation } from '@/types/app-types'
import { PageType } from '@/types/app-types'
import { DiscoverCategory } from '@/types/enums'
import { formatDateWithPattern } from '@/utils'
import { ClickLocation } from '@/utils/analytics/types'

import Blog from './components/blog'
import BrowseTopCategories from './components/browse-top-categories'
import BuyerGuarantee from './components/buyer-guarantee'
import DiscoverCategoryItems from './components/discover-category-items'
import FreeTickets from './components/free-tickets'
import GeolocatedHomepageHero from './components/geolocated-homepage-hero'
import HomePageHero from './components/homepage-hero'
import Newsletter from './components/newsletter'
import PromoHero from './components/promo-hero'
import RecentlyViewedPerformers from './components/recently-viewed'
import TopPicks from './components/top-picks'
import { useGeolocatedHero } from './hooks/use-geolocated-hero'
import useOptimizelyUser from './hooks/use-optimizely-user'
import styles from './styles.module.scss'
import { HomepageRedesignVariable } from './types'
import { getHomepageRedesignVariable } from './utils'

const SHOULD_RENDER_HOMEPAGE_HERO = process.env.NEXT_PUBLIC_SHOULD_RENDER_HOMEPAGE_HERO === 'true'

export interface HomePageProps extends WithOptimizelyProps {
    initialCmsHomeData?: CmsHomeDataType
    initialUserLocationData?: UserLocation
}

const HomePage: NextPage<HomePageProps> = ({ initialCmsHomeData, optimizely }) => {
    const todayString = formatDateWithPattern(dayjs(), 'YYYY-MM-DD')
    const oneWeekFromTodayString = formatDateWithPattern(dayjs().add(7, 'day'), 'YYYY-MM-DD')
    const USD_CURRENCY = 'USD'

    const { type } = useWindowSize()

    useOptimizelyUser(optimizely)

    const [isNflHomePageFeatureEnabled] = useNflHomePageFeature(optimizely)
    const [isSearchBarFeatureEnabled] = useSearchBarFeature(optimizely)
    const [isNflHomepageSuperbowlBannerEnable] = useNflHomepageSuperbowlBanner()

    const {
        [HomepageRedesignVariable.SCROLLABLE_ARROWS]: shouldShowScrollableArrows,
        [HomepageRedesignVariable.EXPANDED_LAYOUT]: shouldShowExpandedLayout,
        [HomepageRedesignVariable.ABOVE_THE_FOLD]: shouldAboveTheFold,
        [HomepageRedesignVariable.TILE_CARD_SIZE]: shouldTileCard,
        [HomepageRedesignVariable.EVENTS_UNDER_100]: shouldShowEventsUnder100,
        [HomepageRedesignVariable.BROWSE_TOP_CATEGORIES]: shouldShowTopCategories,
    } = getHomepageRedesignVariable(optimizely, type)

    const [isHomePageDynamicContentEnabled, homePageDynamicContentVariables] = useHomePageDynamicContent(optimizely)
    const shouldShowGeoLocatedHero = isHomePageDynamicContentEnabled && homePageDynamicContentVariables.geolocated

    const [isLocalizedCurrencyEnabled] = useLocalizedCurrencyFeature()
    const [isGeneralAdmissionUKEnabled] = useGeneralAdmissionUKFeature()
    const hideBuyerGuaranteeForCountry = useIsCountryFeatured(BUYER_GUARANTEE_EXCLUDED_COUNTRIES)
    const displayFreeTicketsHero = !(isGeneralAdmissionUKEnabled && hideBuyerGuaranteeForCountry)

    // client-side rendering check for top-pick
    const [hasMounted, setHasMounted] = useState(false)

    useEffect(() => setHasMounted(true), [])

    useTrackPageView(PageType.Home)
    // This is to force page to re-render after searchbar click so that it realizes the change in isSearchbarFeatureEnabled
    const {
        state: { toggled: isBackdropOpen },
    } = useContext(backdropContext)

    const {
        state: { data: location },
    } = useContext(UserLocationContext)

    const forceUpdate = useForceUpdate()

    useEffect(() => {
        if (isBackdropOpen) {
            forceUpdate()
        }
    }, [isBackdropOpen, forceUpdate])

    const geolocatedHeroData = useGeolocatedHero(location)

    const {
        selectors: { selectedCurrency },
    } = useContext(InternationalizationContext)

    const reverseRecentViewAndTopPickClass = classNames(styles.topPickAndRecentViewContainer, {
        [styles.reverseRecentViewAndTopPick]: shouldAboveTheFold,
    })

    const renderHero = useCallback(() => {
        if (shouldAboveTheFold || isNflHomepageSuperbowlBannerEnable) {
            return <></>
        }

        if (!SHOULD_RENDER_HOMEPAGE_HERO) {
            return <PromoHero />
        }

        if (shouldShowGeoLocatedHero) {
            return <GeolocatedHomepageHero geolocatedData={geolocatedHeroData} />
        }

        return <HomePageHero />
    }, [geolocatedHeroData, isNflHomepageSuperbowlBannerEnable, shouldAboveTheFold, shouldShowGeoLocatedHero])

    const shouldLoadAdditionalProductionsNearYou =
        shouldShowScrollableArrows || shouldShowExpandedLayout || shouldTileCard

    const pageSize = shouldLoadAdditionalProductionsNearYou ? 12 : 4

    const displayEventsUnder100 = isLocalizedCurrencyEnabled
        ? shouldShowEventsUnder100 && selectedCurrency === USD_CURRENCY
        : shouldShowEventsUnder100

    const TopPickComponent = useCallback(() => {
        return (
            <TopProductionsNearYouProvider
                initialParams={{
                    pageSize,
                    includeIpAddress: true,
                    radius: MAX_NEARBY_DISTANCE,
                    startDate: todayString,
                    endDate: oneWeekFromTodayString,
                    sortBy: 'RANK',
                    excludeParking: true,
                    distinct: true,
                }}
            >
                <TopPicks
                    trackingCarouselLocation={1}
                    trackingClickLocation={ClickLocation.HOME_PAGE_TOP_PICKS}
                    trackingViewProductionList={AnalyticsSubIds.TopPicks}
                />
            </TopProductionsNearYouProvider>
        )
    }, [oneWeekFromTodayString, pageSize, todayString])

    const analyticsProps = useMemo(() => ({ id: AnalyticsIds.Home, pageType: PageType.Home }), [])

    return (
        <AnalyticsProvider initialProps={analyticsProps}>
            <Meta url={URLS.VIVIDSEATS} {...initialCmsHomeData?.meta} />
            <JsonLd />
            <AccessibilityNavigation />
            <div className={styles.background}>
                <HeaderStyleProvider initialProps={{ isTransparent: false, listenForScroll: false }}>
                    {isSearchBarFeatureEnabled ? (
                        <StickySearchBarHeader withTransparentHero />
                    ) : (
                        <Header withTransparentHero />
                    )}
                </HeaderStyleProvider>
                <Box id={ACCESSIBILITY_IDS.CONTENT}>
                    {renderHero()}
                    {hasMounted && (
                        <>
                            {shouldAboveTheFold || isNflHomepageSuperbowlBannerEnable ? (
                                <div
                                    data-testid="reverseTopPickAndRecentViewContainer"
                                    className={reverseRecentViewAndTopPickClass}
                                >
                                    {isNflHomepageSuperbowlBannerEnable && <NflHomePageBanner />}
                                    <TopPickComponent />
                                    {isNflHomePageFeatureEnabled && <NflList />}
                                    <RecentlyViewedPerformers />
                                </div>
                            ) : (
                                <div
                                    data-testid="topPickAndRecentViewContainer"
                                    className={reverseRecentViewAndTopPickClass}
                                >
                                    <RecentlyViewedPerformers />
                                    {isNflHomePageFeatureEnabled && <NflList />}
                                    <TopPickComponent />
                                </div>
                            )}
                        </>
                    )}

                    {displayEventsUnder100 && (
                        <TopProductionsNearYouProvider
                            initialParams={{
                                pageSize: 4,
                                includeIpAddress: true,
                                radius: MAX_NEARBY_DISTANCE,
                                startDate: todayString,
                                endDate: oneWeekFromTodayString,
                                sortBy: 'RANK',
                                excludeParking: true,
                                distinct: true,
                                minListingPriceCeiling: 100,
                            }}
                        >
                            <TopPicks
                                trackingCarouselLocation={2}
                                title="Events Under $100"
                                shouldShowUserLocation={false}
                                shouldShowEventsUnder100
                                trackingClickLocation={ClickLocation.HOME_PAGE_EVENTS_UNDER}
                                trackingViewProductionList={AnalyticsSubIds.EventsUnder}
                                optimizelyTrackEvent={OPTIMIZELY_EVENT.eventsUnder100Click}
                            />
                        </TopProductionsNearYouProvider>
                    )}
                    {shouldShowTopCategories && <BrowseTopCategories />}
                    {displayFreeTicketsHero && <FreeTickets />}
                    <CmsHomeProvider
                        initialProps={{
                            data: initialCmsHomeData,
                            selectedDiscoverCategory: DiscoverCategory.TOP_PICKS,
                        }}
                    >
                        <DiscoverCategoryItems />
                        <Blog />
                    </CmsHomeProvider>
                    <BuyerGuarantee />
                    <Newsletter />
                </Box>
                <Footer />
            </div>
        </AnalyticsProvider>
    )
}

HomePage.displayName = 'HomePage'

export const getServerSideProps: GetServerSideProps<Omit<HomePageProps, keyof WithOptimizelyProps>> = async () => {
    let initialCmsHomeData: CmsHomeDataType | undefined

    try {
        ;[initialCmsHomeData] = await Promise.all([cmsServerSideRead({})])
    } catch (e) {
        console.error((e as Error).message)
    }
    return {
        props: {
            ...(initialCmsHomeData ? { initialCmsHomeData } : {}),
        },
    }
}

export default withOptimizely(HomePage)
