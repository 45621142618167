import React from 'react'

import { Box } from '@vividseats/vivid-ui-kit'

import PulsingBadge from '@/components/shared/production-badges/components/pulsing-badge'

import GiveawayBadge from './components/giveaway-badge'
import HomeOpenerBadge from './components/home-opener-badge'
import LimitedInventoryBadge from './components/limited-inventory-badge'
import LowPricesDealsAvailableBadge from './components/low-prices-deals-available-badge'
import MondayThursdayNightFootballBadge from './components/monday-thursday-night-football-badge'
import { useOnsalePresaleBadgesFeature, useScarcityBadgesFeature } from './hooks'
import styles from './styles.module.scss'

const MAX_BADGE_COUNT = 2
interface ProductionBadgesType {
    pulsingBadgeLabel?: string
    isLowPricesDealsAvailable?: boolean
    isHomeOpener?: boolean
    hasGiveaway?: boolean
    limitedInventoryBadgeLabel?: string
    mondayThursdayNightFootballBadgeLabel?: string
}

const ProductionBadges: React.FC<ProductionBadgesType> = ({
    isLowPricesDealsAvailable,
    isHomeOpener,
    hasGiveaway,
    limitedInventoryBadgeLabel,
    pulsingBadgeLabel,
    mondayThursdayNightFootballBadgeLabel,
}) => {
    const [isOnsalePresaleBadgingEnabled] = useOnsalePresaleBadgesFeature()
    const isScarcityBadgingEnabled = useScarcityBadgesFeature()

    const badges = []

    if (isOnsalePresaleBadgingEnabled && pulsingBadgeLabel) {
        badges.push(<PulsingBadge key="pulsing" label={pulsingBadgeLabel} />)
    }

    if (hasGiveaway) {
        badges.push(<GiveawayBadge key="giveaway" />)
    }

    if (isScarcityBadgingEnabled && isHomeOpener) {
        badges.push(<HomeOpenerBadge key="home-opener" />)
    }

    if (!!limitedInventoryBadgeLabel) {
        badges.push(<LimitedInventoryBadge key="limited-inventory" label={limitedInventoryBadgeLabel} />)
    }

    if (isScarcityBadgingEnabled && isLowPricesDealsAvailable) {
        badges.push(<LowPricesDealsAvailableBadge key="deals" />)
    }

    if (!!mondayThursdayNightFootballBadgeLabel) {
        badges.push(<MondayThursdayNightFootballBadge key="nfl" label={mondayThursdayNightFootballBadgeLabel} />)
    }

    return (
        <Box className={styles.badgeContainer} display="flex" align="center" paddingTop={2}>
            {badges.slice(0, MAX_BADGE_COUNT)}
        </Box>
    )
}

export default ProductionBadges
