import React, { FC } from 'react'

import { Box, ButtonV2, Text } from '@vividseats/vivid-ui-kit'

import FootballIcon from '../football-icon'

import styles from './styles.module.scss'

type Props = {
    abbreviation: string
    name: string
    url: string
    primaryColor: string
    secondaryColor: string
}

const NflTeam: FC<Props> = ({ abbreviation, name, url, primaryColor, secondaryColor }) => {
    return (
        <Box display="flex" justify="between" align="center" flexGrow flexShrink>
            <Box display="flex" flexShrink align="center">
                <Box
                    paddingLeft={2}
                    paddingRight={2}
                    display="flex"
                    className={styles.thumbnail}
                    style={{ backgroundColor: primaryColor }}
                    align="center"
                    justify="center"
                >
                    <Box marginRight={1} display="flex" align="center">
                        <FootballIcon fontSize="7px" color={secondaryColor} />
                    </Box>
                    <Text size="xs" weight="bold" altFont>
                        {abbreviation}
                    </Text>
                </Box>
                <Text className={styles.name} size="md" weight="regular-bold" altFont>
                    {name}
                </Text>
            </Box>
            <ButtonV2 href={url} size="small" importance="secondary">
                Find Tickets
            </ButtonV2>
        </Box>
    )
}

export default NflTeam
