export const MAXIM_IMG_ALT_TEXT = 'Maxim Casino Royale'
export const MAXIM_IMG_SRC_MOBILE = `https:${process.env.NEXT_PUBLIC_EXTERNAL_IMAGE_URL_PREFIX}/v1705599305/Product/assets/banners/homepage-maxim/s-banner-maxim.png`
export const MAXIM_IMG_SRC_TABLET = `https:${process.env.NEXT_PUBLIC_EXTERNAL_IMAGE_URL_PREFIX}/v1705599305/Product/assets/banners/homepage-maxim/m-banner-maxim.png`
export const MAXIM_IMG_SRC_DESKTOP = `https:${process.env.NEXT_PUBLIC_EXTERNAL_IMAGE_URL_PREFIX}/v1705599306/Product/assets/banners/homepage-maxim/l-banner-maxim.png`
export const MAXIM_IMG_SRC_DESKTOP_XL = `https:${process.env.NEXT_PUBLIC_EXTERNAL_IMAGE_URL_PREFIX}/v1705599306/Product/assets/banners/homepage-maxim/xl-banner-maxim.png`
export const MAXIM_IMG_DESTINATION_LINK = 'https://maximparty.vividseats.com/'

export const GAMECENTER_IMG_ALT_TEXT = 'Game Center'
export const GAMECENTER_IMG_SRC_MOBILE = `https:${process.env.NEXT_PUBLIC_EXTERNAL_IMAGE_URL_PREFIX}/v1705594475/Product/assets/banners/gc-big-game-challenge/s-banner-big-game-challenge.png`
export const GAMECENTER_IMG_SRC_TABLET = `https:${process.env.NEXT_PUBLIC_EXTERNAL_IMAGE_URL_PREFIX}/v1706119160/Product/assets/banners/gc-big-game-challenge/m-banner-big-game-challenge-qr.png`
export const GAMECENTER_IMG_SRC_DESKTOP = `https:${process.env.NEXT_PUBLIC_EXTERNAL_IMAGE_URL_PREFIX}/v1706119160/Product/assets/banners/gc-big-game-challenge/l-banner-big-game-challenge-qr.png`
export const GAMECENTER_IMG_SRC_DESKTOP_XL = `https:${process.env.NEXT_PUBLIC_EXTERNAL_IMAGE_URL_PREFIX}/v1706119160/Product/assets/banners/gc-big-game-challenge/xl-banner-big-game-challenge-qr.png`
export const GAMECENTER_IMG_DESTINATION_LINK =
    'https://gamecenter.vividseats.com/biggamegiveaway?utm_source=brand_team&utm_medium=homepage&utm_campaign=biggamegiveaway&utm_content=homepage_hero_button'
export const GAMECENTER_IMG_LEGAL_LINK = 'https://gamecenter.vividseats.com/tandc'
