import React from 'react'

import { Pill, Text } from '@vividseats/vivid-ui-kit'

import styles from './styles.module.scss'

const GiveawayBadge: React.FC = () => {
    return (
        <Pill size="lg" className={styles.giveaway} data-testid="giveaway-pill">
            <Text altFont weight="regular-bold">
                <span aria-label="gift box" role="img">
                    🎁
                </span>{' '}
                Giveaway
            </Text>
        </Pill>
    )
}

export default GiveawayBadge
